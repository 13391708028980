<template>
  <div>
    <v-container>
      <h2 class="mb-15">Sobre el 11…</h2>

      <p class="lead">
        <strong>El 11 de Manolo</strong>, es una sección del programa radial
        "Los Tenores" de
        <strong>
          <a href="https://www.adnradio.cl/" target="_blank" class="text-link"
            >Radio ADN</a
          ></strong
        >

        a cargo del periodista Manuel Fernández, donde se crean equipos de
        fútbol, que generalmente sale a la cancha cada jueves, acorde a la
        contingencia y efemérides nacionales e internacionales con jugadores que
        tiene relación al nombre del equipo. Eso sí, existen reglas: no se
        permiten apodos (salvo excepciones) y siempre se respeta la posición del
        jugador conforme al equipo. A veces, los equipos incluyen bancas,
        cuerpos técnicos, estadios, equipos rivales, lugares de concentración e
        inclusos jefes de prensa, entre otros.<br />
        <br />
        Los creadores del sitio no se adjudica ningún crédito del contenido
        correspondiente al "11 de Manolo" y se los atribuye direcamente a los
        autores intelectuales.
        <br />
        <br />
        Proyecto desarrollado por
        <strong
          ><a
            href="https://www.linkedin.com/in/pabsneyra/"
            class="text-link"
            target="_blank"
            >Pablo</a
          >.</strong
        >
        <br />
        Agradecimiento a L.S, L.A y "Los Tenores" de ADN.
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
h2 {
  font-size: 5rem;
  line-height: 1;
}

p.lead {
  font-family: "Spartan", sans-serif;
  line-height: 2.5;
  font-size: 0.8rem;
  font-weight: 500;
}

.text-link {
  text-decoration: none;
  color: #1b5e20;
}

/* 600px and down */
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 3.5rem;
  }
}
</style>
